import config from '/src/config.js';

function compareNames(a, b) {
	return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
}

export default {
	name: 'SETS',
	actions: {
		FETCH_SETS: {
			url: 'api/sets.php',
			onSuccess: (data) => {
				// // URI start with slash, so no need to prefix
				// data.forEach((photo) => {
				// 	photo.image = `${config.imageBasePath || ''}${photo.image}`;
				// });
				return data;
			}
		}
	}
};
