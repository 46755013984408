import YAML from 'yaml';
import config from '/src/config.js';
import makeStore from './makeStore';

function generate(stuff) {
	const generated = {};
	for (const item of stuff) {
		const {
			name,
			type,
			actions,
			url,
			formatUrl,
			onSuccess,
			onError,
			getOptions,
			reducer: itemReducer
		} = item;

		const lcname = name.toLowerCase();

		function makeDispatcher(dispatch) {
			return async (action) => {
				if (actions) {
					if (!actions.hasOwnProperty(action.type)) {
						dispatch(action);
						return;
					}
					const {
						url,
						onSuccess,
						onError
					} = actions[action.type];

					dispatch(action);

					const actionType = typeof actions[action.type].url;
					let fetchArgs = [];
					if (actionType === 'string') {
						fetchArgs.push(
							`${config.imageBasePath}/${actions[action.type].url}`
						);
					} else if (actionType === 'function') {
						fetchArgs = actions[action.type].url(action);
					}

					// fetch
					const response = await fetch(...fetchArgs);

					const contentType = response.headers.get('content-type');
					let data;
					if (response.status !== 204) {
						try {
							if (contentType.startsWith('application/yaml')) {
								data = YAML.parse(await response.text());
							} else {
								data = await response.json();
							}
						} catch (err) {
							console.error(err);
						}
					}

					// parse
					if (response.ok) {
						if (onSuccess) {
							data = onSuccess(data, { action, response });
							if (data === undefined) {
								console.warn(`${name}.onSuccess returned undefined`);
							}
						}
						dispatch({
							type: `${action.type}_SUCCESS`,
							payload: data
						});
					} else {
						if (onError) {
							data = onError(data, action);
							if (data === undefined) {
								console.warn(`${name}.onError returned undefined`);
							}
						}
						dispatch({
							type: `${action.type}_ERROR`,
							payload: data
						});
					}
					return;
				}
			};
		}

		const reducer = (state, action) => {
			// console.log('action', action && action.type, action);
			if (action.type === `FETCH_${name}`) {
				return {
					...state,
					[lcname]: {
						finishedLoading: false
					}
				}
			} else if (action.type === `FETCH_${name}_SUCCESS`) {
				return {
					...state,
					[lcname]: {
						finishedLoading: true,
						data: action.payload
					}
				};
			} else if (action.type === `FETCH_${name}_ERROR`) {
				return {
					...state,
					[lcname]: {
						finishedLoading: true,
						data: action.payload
					}
				};
			}
			return state;
		};

		const initialState = {
			[lcname]: {
				finishedLoading: item.initialState !== undefined,
				data: item.initialState !== undefined ? item.initialState : []
			}
		};
		const [
			Provider,
			useStore,
			useDispatch
		] = makeStore(itemReducer || reducer, initialState);

		generated[lcname] = {
			makeDispatcher,
			Provider,
			useStore,
			useDispatch
		};
	}
	return generated;
}

import downloads from './downloads';
import event from './event';
import events from './events';
import login from './login';
import messages from './messages';
import sets from './sets';

export default generate([
	downloads,
	event,
	events,
	login,
	messages,
	sets
]);
