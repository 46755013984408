import React from 'react';
import contexts from './contexts';

const { Provider: EventsProvider } = contexts.events;
const { Provider: EventProvider } = contexts.event;
const { Provider: MessagesProvider } = contexts.messages;
const { Provider: SetsProvider } = contexts.sets;
const { Provider: LoginProvider } = contexts.login;

const GlobalContext = ({ children }) => {
	return (
		<EventProvider>
			<EventsProvider>
				<MessagesProvider>
					<SetsProvider>
						<LoginProvider>
							{children}
						</LoginProvider>
					</SetsProvider>
				</MessagesProvider>
			</EventsProvider>
		</EventProvider>
	);
};

export default GlobalContext;
