import config from '/src/config.js';
import {
	compareDates,
	getNextFridayDate,
	addEventMetadata,
	removeEventMetadata
} from '../utils/EventUtils.js';

const debug = false;
const log = debug ? console.log : () => {};

// const sortEvents = (a, b) => (a > b) ? 1 : (a < b) ? -1 : 0;

function sortEvents(eventa, eventb) {
	const a = eventa.date;
	const b = eventb.date;
	return (a > b) ? 1 : (a < b) ? -1 : 0;
}

export default {
	name: 'EVENTS',
	url: `${config.imageBasePath}/api/events.php`,
	actions: {
		FETCH_EVENTS: {
			url: 'api/events.php',
			onSuccess: (data, { action }) => {
				const events = data;
				events.forEach(addEventMetadata);
				events.sort(compareDates);
				return events;
			}
		},
		FETCH_EVENTS_NEXT: {
			url: 'api/events-next.php',
			onSuccess: (events, { action }) => {
				events.forEach(addEventMetadata);
				events.sort(compareDates);
				return events;
			}
		},
		FETCH_EVENTS_FULL: {
			url: 'api/events.php?summary=0',
			onSuccess: (data, { action }) => {
				const events = data;
				events.forEach(addEventMetadata);
				events.sort(compareDates);
				return events;
			}
		},
		DELETE_ALL_EVENTS: {
			url: (action) => ([
				`${config.imageBasePath}/api/events.php/clear`,
				{
					method: 'DELETE',
					credentials: 'include',
					headers: {
						Authorization: sessionStorage.getItem('credentials')
					}
				}
			])
		},
		DELETE_EVENT: {
			url: (action) => ([
				`${config.imageBasePath}/api/events.php/${action.payload.event.date}`,
				{
					method: 'DELETE',
					credentials: 'include',
					headers: {
						Authorization: sessionStorage.getItem('credentials')
					}
				}
			])
		},
		UPDATE_EVENT: {
			url: (action) => ([
				`${config.imageBasePath}/api/events.php/${action.payload.event.date}`,
				{
					method: 'PUT',
					credentials: 'include',
					headers: {
						Authorization: sessionStorage.getItem('credentials'),
						'Content-Type': 'application/json;charset=utf-8'
					},
					body: JSON.stringify(removeEventMetadata(action.payload.event))
				}
			]),
			onSuccess: (data, { action }) => {
				log('updated', data, action);
				if (action.onSuccess) {
					action.onSuccess();
				}
				return data;
			}
		},
		CREATE_EVENT: {
			url: (action) => ([
				`${config.imageBasePath}/api/events.php`,
				{
					method: 'POST',
					credentials: 'include',
					headers: {
						Authorization: sessionStorage.getItem('credentials'),
						'Content-Type': 'application/json;charset=utf-8'
					},
					body: JSON.stringify(removeEventMetadata(action.payload.event))
				}
			]),
			onSuccess: (data, { action }) => {
				log('created', data, action);
				if (action.onSuccess) {
					action.onSuccess();
				}
				return data;
			}
		}
	},
	reducer: (state, action) => {
		log('events', { type: action.type, state, action });
		if (action.type === 'FETCH_EVENTS') {
			return {
				...state,
				events: {
					finishedLoading: false
				}
			}
		} else if (['FETCH_EVENTS_SUCCESS', 'FETCH_EVENTS_NEXT_SUCCESS'].includes(action.type)) {
			return {
				...state,
				events: {
					finishedLoading: true,
					data: action.payload
				}
			};
		} else if (action.type === 'FETCH_EVENTS_ERROR') {
			return {
				...state,
				events: {
					finishedLoading: true,
					data: action.payload
				}
			};
		} else if (action.type === 'FETCH_EVENTS_FULL') {
			return {
				...state,
				events: {
					finishedLoading: false
				}
			}
		} else if (action.type === 'FETCH_EVENTS_FULL_SUCCESS') {
			return {
				...state,
				events: {
					finishedLoading: true,
					data: action.payload
				}
			};
		} else if (action.type === 'FETCH_EVENTS_FULL_ERROR') {
			return {
				...state,
				events: {
					finishedLoading: true,
					data: action.payload
				}
			};
		} else if (action.type === 'UPDATE_EVENT_SUCCESS') {
			log('state.events', state.events);
			const events = [ ...state.events.data ];
			const index = events.findIndex(a => a.date === action.payload.date);
			if (index >= 0) {
				events[index] = addEventMetadata(action.payload);
			} else {
				// should never happen, but anyway...
				events.push(action.payload);
			}

			return {
				...state,
				events: {
					finishedLoading: true,
					data: events
				}
			};
		} else if (action.type === 'CREATE_EVENT_SUCCESS') {
			log('state.events', state.events);
			const cevents = [ ...state.events.data ];
			const index = cevents.findIndex(a => a.date === action.payload.date);
			if (index < 0) {
				// add new event
				cevents.push(addEventMetadata(action.payload));
			}
			cevents.sort(sortEvents);

			return {
				...state,
				events: {
					finishedLoading: true,
					data: cevents
				}
			};
		} else if (action.type === 'DELETE_EVENT_SUCCESS') {
			const events = [ ...state.events.data ];
			const index = events.findIndex(a => a.date === action.payload.date);
			if (index >= 0) {
				events.splice(index, 1);
			}
			return {
				...state,
				events: {
					finishedLoading: true,
					data: events
				}
			};
		} else if (action.type === 'DELETE_ALL_EVENTS_SUCCESS') {
			const events = [];
			return {
				...state,
				events: {
					finishedLoading: true,
					staleEvents: true,
					data: events
				}
			};
		}
		return state;
	}
};
