import config from '/src/config.js';

const debug = true;
const log = debug ? console.log : () => {};

function encode(credentials) {
	return `Basic ${btoa(credentials.username + ':' + credentials.password)}`;
}

export default {
	name: 'LOGIN',
	initialState: {
		logged: !!sessionStorage.getItem('credentials'),
		staleLogin: false
	},
	actions: {
		FETCH_LOGIN: {
			url: (action) => ([
				`${config.imageBasePath}/api/login.php`,
				{
					method: 'POST',
					headers: {
						Authorization: encode(action.payload)
					}
				}
			]),
			onSuccess: (data, { action }) => {
				sessionStorage.setItem('credentials', encode(action.payload));
				return data;
			},
			onError: (data, action) => {
				console.error('error logging in', data);
				if (action.onError) {
					action.onError(data);
				}
				return data;
			}
		},
		FETCH_LOGOUT: {
			url: (action) => ([
				`${config.imageBasePath}/api/login.php`,
				{
					method: 'DELETE',
					headers: {
						Authorization: encode(sessionStorage.getItem('credentials'))
					}
				}
			]),
			onSuccess: (data, { action }) => {
				sessionStorage.removeItem('credentials');
				return data;
			},
			onError: (data) => {
				console.log('error logging out', data);
				return data;
			}
		}
	},
	reducer: (state, action) => {
		log('events', { type: action.type, state, action });
		if (action.type === 'FETCH_LOGIN') {
			return {
				...state,
				login: {
					finishedLoading: false,
					staleLogin: false,
				}
			}
		} else if (action.type === 'FETCH_LOGOUT') {
			return {
				...state,
				login: {
					finishedLoading: false,
					// staleLogin: true
				}
			}
		} else if (action.type === 'FETCH_LOGIN_SUCCESS') {
			return {
				...state,
				login: {
					finishedLoading: true,
					data: action.payload
				}
			};
		} else if (action.type === 'FETCH_LOGOUT_SUCCESS') {
			return {
				...state,
				login: {
					finishedLoading: true,
					staleLogin: true,
					data: action.payload
				}
			};
		} else if (action.type === 'FETCH_LOGIN_ERROR'
			|| action.type === 'FETCH_LOGOUT_ERROR') {
			return {
				...state,
				events: {
					finishedLoading: true,
					data: action.payload
				}
			};
		}
	}
};
