import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import Home from 'pages/home/Home';
import About from 'pages/about/About';
import Archive from 'pages/archive/Archive';
import Attitude from 'pages/archive/attitude/Attitude';
import AtSwimTwoBirds from 'pages/archive/at-swim-two-birds/AtSwimTwoBirds';
import BanksOfTheBoro from 'pages/archive/banks-of-the-boro/BanksOfTheBoro';
import BuckramBack from 'pages/archive/buckram-back/BuckramBack';
import CountryDance from 'pages/archive/country-dance/CountryDance';
import DanceLight from 'pages/archive/dance-light/DanceLight';
import EvilsOfDancing from 'pages/archive/evils-of-dancing/EvilsOfDancing';
import Ecstasy from 'pages/archive/ecstasy/Ecstasy';
import LanigansBall from 'pages/archive/lanigans-ball/LanigansBall';
import BookReview from 'pages/archive/book-review/BookReview';
import DanceAtMarley from 'pages/archive/dance-at-marley/DanceAtMarley';
import DanceHalls from 'pages/archive/dance-halls/DanceHalls';
import OuldIrishJig from 'pages/archive/ould-irish-jig/OuldIrishJig';
import DanceHallsAct from 'pages/archive/dance-halls-act/DanceHallsAct';
import Trenchmore from 'pages/archive/trenchmore/Trenchmore';
import Calendar from 'pages/calendar/Calendar';
import CalendarEvent from 'pages/calendar/CalendarEvent';
import EditEvent from 'pages/calendar/EditEvent';
import Sets from 'pages/sets/Sets';
import Set from 'pages/sets/Set';
import Login from 'pages/login/Login';
import MainLayout from './layout/MainLayout';
import HomeLayout from './layout/HomeLayout';

const router = createBrowserRouter([{
	element: <HomeLayout />,
	children: [{
		path: '/',
		element: <Home />
	}, {
		path: '/home',
		element: <Home />
	}]
}, {
	element: <MainLayout />,
	children:[{
		path: '/about',
		element: <About />
	}, {
		path: '/archive',
		element: <Archive />
	}, {
		path: '/archive/attitude',
		element: <Attitude />
	}, {
		path: '/archive/at-swim-two-birds',
		element: <AtSwimTwoBirds />
	}, {
		path: '/archive/banks-of-the-boro',
		element: <BanksOfTheBoro />
	}, {
		path: '/archive/buckram-back',
		element: <BuckramBack />
	}, {
		path: '/archive/country-dance',
		element: <CountryDance />
	}, {
		path: '/archive/dance-light',
		element: <DanceLight />
	}, {
		path: '/archive/evils-of-dancing',
		element: <EvilsOfDancing />
	}, {
		path: '/archive/ecstasy',
		element: <Ecstasy />
	}, {
		path: '/archive/lanigans-ball',
		element: <LanigansBall />
	}, {
		path: '/archive/book-review',
		element: <BookReview />
	}, {
		path: '/archive/dance-at-marley',
		element: <DanceAtMarley />
	}, {
		path: '/archive/dance-halls',
		element: <DanceHalls />
	}, {
		path: '/archive/ould-irish-jig',
		element: <OuldIrishJig />
	}, {
		path: '/archive/dance-halls-act',
		element: <DanceHallsAct />
	}, {
		path: '/archive/trenchmore',
		element: <Trenchmore />
	}, {
		path: '/calendar/:date',
		element: <CalendarEvent />
	}, {
		path: '/calendar/:date/edit',
		element: <EditEvent />
	}, {
		path: '/calendar',
		element: <Calendar />
	}, {
		path: '/set-dances/:set',
		element: <Set />
	}, {
		path: '/set-dances',
		element: <Sets />
	}, {
		path: '/login',
		element: <Login />
	}, {
		path: '*',
		element: <Navigate to="/" />,
	}]
}]);

const App = () => {
	return (
		<RouterProvider router={router}>
		</RouterProvider>
	);
};

export default App;
